window.addEventListener('DOMContentLoaded', () => {
    const footer = document.querySelectorAll('.section-footer__navigation');
    footer.forEach((el) => {
        const title = el.querySelector('.title');
        const ul = el.querySelector('ul');
        let collapsed = false;
        let height = 0;

        const isMobile = window.matchMedia('(max-width: 959px)');

        const onclick = () => {
            if (collapsed) {
                ul.style.height = `${height}px`;
                el.classList.remove('collapse');
                setTimeout(() => {
                    ul.style.height = 'auto';
                }, 201);
            } else {
                height = ul.offsetHeight;
                ul.style.height = `${height}px`;
                setTimeout(() => {
                    ul.style.height = '0px';
                    el.classList.add('collapse');
                }, 0);
            }
            collapsed = !collapsed;
        };

        const setup = () => {
            collapsed = true;
            height = ul.offsetHeight;
            el.classList.add('collapse');
            ul.style.height = '0px';

            title.addEventListener('click', onclick, false);
        };

        const clear = () => {
            collapsed = false;
            el.classList.remove('collapse');
            ul.style.height = 'auto';

            title.removeEventListener('click', onclick);
        };

        isMobile.addListener(() => {
            clear();

            if (isMobile.matches) {
                setup();
            }
        });

        if (isMobile.matches) {
            setup();
        }
    });
});
