import '../styles/app.scss';

import './_header';
import './_footer';
import './_topics';
import './_forms';
import './_counseling';

import SmoothScroll from 'smooth-scroll';

window.addEventListener('DOMContentLoaded', (() => {
    const scroll = new SmoothScroll('a[href*="#"]', {
        updateURL: false,
    });

    if (window.location.hash && document.querySelector(window.location.hash)) {
        window.scroll(0, 0);
        scroll.animateScroll(document.querySelector(window.location.hash));
    }
}));

(() => {
    const initScreenHeight = () => {
        // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };

    let timeout = false;
    window.addEventListener('resize', () => {
        clearTimeout(timeout);
        timeout = setTimeout(initScreenHeight, 250);
    });
    initScreenHeight();
})();
